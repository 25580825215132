<template>
  <VRow 
    id="create-program"
    class="mx-0"
  >
    <VCol cols="12">
      <p class="mb-0">
        To create a new {{ featureName(modelType, "singularize") }}, please give it a name and a description.
        <span v-if="template">
          Depending on how much content is in the template, it might take a few minutes to import. 
        </span>
      </p>
    </VCol>
    <VCol cols="12">
      <div class="text-subtitle-2 mb-1">
        {{ featureName(modelType, "singularize") }} Title
      </div>
      <TextField
        v-model="form.title"
        hide-details
        style="width: 320px;"
        :error="$v.form.title.$error"
        :placeholder="`New ${featureName(modelType, 'singularize')} Name (Required)`"
        @blur="$v.$touch()"
        @input="$v.form.title.$touch()"
      />
    </VCol>
    <VCol cols="12">
      <div class="text-subtitle-2 mb-1">
        {{ featureName(modelType, "singularize") }} Description
      </div>
      <VTextarea
        v-model="form.content"
        filled
        auto-grow
        outlined
        hide-details
        height="80px"
        placeholder="Description (Required)"
        style="width: 440px;"
        :error="$v.form.content.$error"
        @click="$v.$touch()"
        @blur="$v.$touch()"
      />
    </VCol>
    <VCol cols="12">
      <div class="text-subtitle-2 mb-1">
        {{ featureName(modelType, "singularize") }} Emoji
      </div>
      <EmojiAvatar
        alt-view
        ref="emojiAvatar"
        :item="form"
        @change="onChangeEmojiAvatar"
      />
    </VCol>
    <VCol cols="12">
      <div class="text-subtitle-2 mb-1">
        Location
      </div>
      <GeoSelectCity
        alt-view
        width="320px"
        :countries="options.countries"
        :current-city="form.city"
        :current-city-id="form.city_id"
        :confirm-btn-style="{
          backgroundColor: '#2196f3',
          borderColor: '#2196f3',
          color: 'white !important',
        }"
        @citySelected="onCitySelected"
        @cityDetails="(city) => city_name = city.name"
        @stateDetails="(state) => state_name = state.name"
        @removeCity="form.city = form.city_id = null"
      />
    </VCol>
  </VRow>
</template>
<script>
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import FormMixin from "@/mixins/Form";
import EmojiAvatar from "@/components/Elements/Data/EmojiAvatar";

export default {
  name: "ProgramsCreateForm",
  mixins: [FormMixin],
  props: {
    template: {
      type: Object,
      default: null,
    },
  },
  components: {
    EmojiAvatar,
  },
  data() {
    return {
      modelType: "Program",
      city_name: "",
      state_name: "",
      options: {
        countries: [],
      },
      defaultForm: {
        title: this.template ? "[Copy] " + this.template.title : "",
        city_id: null,
        content: "",
        emoji: null,
        background_color: null,
        visible_to: ['mentor', 'student'],
      },
    };
  },
  watch: {
    city_name(value) {
      if(value) {
        this.form.city = {
          name: value,
          parent: {
            name: this.state_name,
          },
        };
      }
    },
    $v: {
      handler: function(value) {
        this.$emit("disabled", value.$invalid || value.$error);
      },
      deep: true,
    },
  },
  validations: {
    form: {
      title: {
        required,
      },
      content: {
        required,
      },
    },
  },
  methods: {
    ...mapActions({
      doGetFormConfig: "program/getFormConfig",
      doSaveProgram: "program/save",
    }),
    onGetFormConfig() {
      return this.doGetFormConfig();
    },
    onChangeEmojiAvatar(emoji, color) {
      this.form.background_color = color;
      this.form.emoji = emoji;
    },
    onCitySelected(cityId) {
      this.form.city_id = cityId;
    },
    onSave() {
      this.$emit("loading", true);
      if(this.template) {
        this.form.template_id = this.template.id;
      }
      return this.saveFormModel(this.doSaveProgram, null, this.form, null, false).then((result) => {
        if(result) {
          this.$emit("close");
          this.$emit("loading", false);
          this.$bus.$emit(
            "notificationMessage",
            `Let’s Go! Your new ${ this.featureName(this.modelType, "singularize") } has been created.`
          );
        }
        return result;
      });
    },
  },
};
</script>
<style lang="scss">
  #create-program {
    .error--text {
      border-radius: 10px;
      fieldset {
        border: none !important;
      }
    }
    input, textarea, input::placeholder, textarea::placeholder {
      font-size: 14px;
      font-weight: 500;
    }
    .v-input__control > .v-input__slot {
      background-color: white;
    }
  }
</style>